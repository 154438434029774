"use client";

import { useEffect } from "react";

import { initTCF } from "./init";

const spidClientId = process.env.NEXT_PUBLIC_SPID_CLIENT_ID || "";
const groupPmId = process.env.NEXT_PUBLIC_PRIVACY_MANAGER_ID || "";
const TCFHrefProperty = process.env.NEXT_PUBLIC_TCF_PROPERTY_HREF === "true";
const TCFPropertyId = process.env.NEXT_PUBLIC_TCF_PROPERTY_ID || "";
const TCFDomain = process.env.NEXT_PUBLIC_TCF_DOMAIN || "";
const brandName = process.env.NEXT_PUBLIC_UI_THEME || "";

interface TCFInitProps {
  domain: string;
  propertyId: number;
  propertyHref?: string;
  groupPmId: number;
  userId?: number;
  clientId: string;
}

function TCFProvider({ userId }: { userId?: string }) {
  useEffect(() => {
    const tcfConfig: TCFInitProps = {
      clientId: spidClientId,
      groupPmId: Number(groupPmId),
      propertyId: Number(TCFPropertyId),
      domain: TCFDomain,
    };

    if (TCFHrefProperty) {
      tcfConfig.propertyHref = `https://web-pre.${
        brandName === "core" ? "omni" : "omniekonomi"
      }.se`;
    }

    if (userId) {
      const uId = parseInt(userId);
      if (!isNaN(uId)) {
        tcfConfig.userId = uId;
      }
    }

    initTCF(tcfConfig);
  }, [userId]);

  return null;
}

export type { TCFInitProps };

//eslint-disable-next-line
export default TCFProvider;
