"use client";

import { useEffect } from "react";

import { getApnTag, getPPIDWithTagValues } from ".";

const brandName = process.env.NEXT_PUBLIC_UI_THEME;

const isDebugActivated = !!process.env.NEXT_PUBLIC_AST_DEBUG_ENABLED || false;

const AdSettings = () => {
  useEffect(() => {
    getPPIDWithTagValues()
      .then(([ppIds, ppIdsTags]) => {
        getApnTag()
          .then((apntag) => {
            isDebugActivated && apntag?.loaded && apntag.enableDebug();
            apntag.anq.push(function () {
              apntag.setPageOpts({
                member: 9943,
                user: {
                  userIds: [{ type: "extendedIDs", eids: ppIds }],
                },
                keywords: {
                  "se-generic-page": "index",
                  "aa-sch-country_code": "se",
                  "aa-sch-publisher": brandName,
                  "se-generic-appmode": "notinapp",
                  "se-generic-pagegen": "smp",
                  "se-generic-screenwidth": window.outerWidth,
                  "se-generic-screenheight": window.outerHeight,
                  "se-generic-viewportwidth": window.innerWidth,
                  "se-generic-viewportheight": window.innerHeight,
                },
              });
            });
          })
          .catch((e) => {
            console.error(`apntag is not loaded, ${e}`);
          });
      })
      .catch((e) => {
        console.error(`Couldn't get ppid with tags`);
      });
  }, []);
  return null;
};

export { AdSettings };
