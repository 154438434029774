"use client";

import Script from "next/script";

import { useTcfPermission } from "../../tcf/helpers";

interface IterateScriptProps {
  apiKey?: string;
}

// This script loads a tool that is used for user feedback and surveys.
export const LoadIterateScript = ({ apiKey }: IterateScriptProps) => {
  const analyticsConsent = useTcfPermission("CMP:analytics");

  if (!apiKey || !analyticsConsent) {
    return null;
  }

  return (
    <Script
      id="iterate-js-script"
      type="application/javascript"
    >{`window.iterateSettings = {
        apiKey: '${apiKey}'
      };
      
      (function(i,t,e,r,a){if(t.getElementById(r)){return}
      i.IterateObjectName=a;var z=function(){z.c(arguments)};z.q=[];
      z.c=function(args){z.q.push(args)};i[a]=z;
      function l() {var js,fjs=t.getElementsByTagName(e)[0];js=t.createElement(e);js.id=r;js.async=1;js.src="https://platform.iteratehq.com/loader.js";fjs.parentNode.insertBefore(js,fjs)};
      if(t.readyState==="complete") {l();} else if(i.attachEvent) {i.attachEvent('onload', l);} else{i.addEventListener('load', l, false);}
      }(window, document,'script','iterate-js','Iterate'));`}</Script>
  );
};
