import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

interface ArticleModalState {
  isModalOpen: boolean;
  modalReferer?: string;
  setModalReferer: (modalReferer?: string) => void;
  setModalIsOpen: (isModalOpen: boolean) => void;
  openModal: (modalReferer: string) => void;
  closeModal: () => void;
}

export const useArticleModalStore = create<ArticleModalState>()(
  persist(
    (set) => ({
      isModalOpen: false,
      setModalReferer: (modalReferer?: string) =>
        set((state) => ({ ...state, modalReferer })),
      setModalIsOpen: (isModalOpen: boolean) =>
        set((state) => ({ ...state, isModalOpen })),
      openModal: (modalReferer: string) =>
        set((state) => {
          if (state.modalReferer === undefined) {
            return { isModalOpen: true, modalReferer };
          } else {
            return { ...state, isModalOpen: true };
          }
        }),
      closeModal: () =>
        set(() => ({ isModalOpen: false, modalReferer: undefined })),
    }),
    {
      name: "article-modal",
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
