"use client";

import { useEffect } from "react";

import { updateConsent, updatePulseUser } from "../../pulse";
import {
  getTCFSync,
  useTcfPermission,
  waitForConsent,
} from "../../tcf/helpers";

const PulseTracker = ({
  userId,
  isPremium,
}: {
  userId?: string;
  isPremium: boolean;
}) => {
  useEffect(() => {
    updatePulseUser(userId, isPremium);
  }, [userId, isPremium]);

  const analyticsConsent = useTcfPermission("CMP:analytics");
  const advertisingConsent = useTcfPermission("CMP:advertising");
  const marketingConsent = useTcfPermission("CMP:marketing");
  const personalisationConsent = useTcfPermission("CMP:personalisation");

  useEffect(() => {
    getTCFSync((tcf) => {
      tcf.onTcfReady(() => {
        waitForConsent(tcf).then((consents) => {
          updateConsent(consents);
        });
      });
    });
  }, [
    analyticsConsent,
    advertisingConsent,
    marketingConsent,
    personalisationConsent,
  ]);

  return null;
};

export { PulseTracker };
