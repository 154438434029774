import { tcf } from "@schibsted/sourcepoint";

import type { ExtendedTCFConfig } from "@schibsted/sourcepoint";
import type { TCFInitProps } from "./tcfProvider";

import { getIdentityInstance } from "../utils/auth/getIdentityInstance";
import { getPulseTracker } from "../pulse";

function initTCF(tcfConfig: TCFInitProps) {
  const identityObject = getIdentityInstance();
  const pulseTracker = getPulseTracker();

  const config: ExtendedTCFConfig = {
    baseEndpoint: `https://cmp.${tcfConfig.domain}`,
    propertyId: Number(tcfConfig.propertyId),
    propertyHref: tcfConfig.propertyHref,
    consentLanguage: "sv",
    groupPmId: Number(tcfConfig.groupPmId),
    pulseTracker: pulseTracker?.pulseTracker,
    realm: "schibsted.com",
    clientId: tcfConfig.clientId,
    identityObject,
    showInWebview: "tcf",
    userId: tcfConfig?.userId,
    disableSentry: true,
  };

  tcf(window, document, navigator, config);
}
export { initTCF };
