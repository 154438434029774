export const isArticlePage = (pathname: string) => {
  const splitPath = pathname.split("/");
  return splitPath[2] === "a";
};

export const isFrontPage = (pathname: string) => {
  return ["/", "/senaste"].includes(pathname);
};

export const isNewsMixPage = (pathname: string) => {
  return pathname === "/nyhetsmix";
};

export const isCategoryPage = (pathname: string, id?: string) => {
  return !!id?.includes("category") && !isArticlePage(pathname);
};

export const isTopicPage = (pathname: string, id?: string) => {
  return !!id?.includes("story") && !isArticlePage(pathname);
};

export const isSearchPage = (id?: string) => {
  return id === "search";
};
