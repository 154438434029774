"use client";

import { useEffect } from "react";
import { usePathname } from "next/navigation";

import { isConfiguredAndLoaded } from "../components/Ad/utils/constants";
import { reloadAd } from "../components/Ad/utils/xandr";

const ReloadAdsOnClientSideNavigation = () => {
  const pathname = usePathname();

  useEffect(() => {
    isConfiguredAndLoaded.forEach((id) => {
      reloadAd(id);
    });
  }, [pathname]);

  return null;
};

export { ReloadAdsOnClientSideNavigation };
